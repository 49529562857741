<template>
  <v-row>
    <v-col cols="9">
      <v-card class="teros-elevation" style="width: 100%">
        <v-window v-model="currentView">
          <v-window-item>
            <v-card-actions
              class="d-block"
              style="
                position: sticky;
                top: -20px;
                background-color: white;
                z-index: 1;
              "
            >
              <v-item-group v-model="currentStep" class="text-center" mandatory>
                <v-item v-slot="{ active }">
                  <v-btn
                    dense
                    text
                    tile
                    :style="{ opacity: active ? 1 : 0.8 }"
                    @click="currentStep = 0"
                    width="25%"
                  >
                    1 - Escopo
                    <v-icon v-if="!canEdit" class="ml-3" small color="#999">
                      mdi-lock
                    </v-icon>
                  </v-btn>
                </v-item>
                <v-item v-slot="{ active }">
                  <v-btn
                    dense
                    text
                    tile
                    :style="{ opacity: active ? 1 : 0.8 }"
                    @click="currentStep = 1"
                    width="25%"
                  >
                    2 - Tipificação
                    <v-icon v-if="!canEdit" class="ml-3" small color="#999">
                      mdi-lock
                    </v-icon>
                  </v-btn>
                </v-item>
                <v-item v-slot="{ active }">
                  <v-btn
                    dense
                    text
                    tile
                    :style="{ opacity: active ? 1 : 0.8 }"
                    @click="currentStep = 2"
                    width="25%"
                  >
                    3 - Detalhamento
                    <v-icon v-if="!canEdit" class="ml-3" small color="#999">
                      mdi-lock
                    </v-icon>
                  </v-btn>
                </v-item>
                <v-item v-slot="{ active }">
                  <v-btn
                    dense
                    text
                    tile
                    :style="{ opacity: active ? 1 : 0.8 }"
                    @click="currentStep = 3"
                    width="25%"
                  >
                    4 - Resultados
                    <v-icon v-if="!canEdit" class="ml-3" small color="#999">
                      mdi-lock
                    </v-icon>
                  </v-btn>
                </v-item>
              </v-item-group>
              <v-progress-linear
                rounded
                color="success"
                :value="((currentStep + 1) / 4) * 100"
              ></v-progress-linear>
            </v-card-actions>

            <v-card-text class="px-5 v-form-cadastro">
              <v-window v-model="currentStep">
                <!-- ESCOPO -->
                <v-window-item class="pt-2">
                  <v-row v-if="!isClient">
                    <v-col :cols="isClient ? 12 : 6" lg="8" sm="12" xs="12">
                      <kore-select
                        v-model="projeto.empresaId"
                        :valid.sync="fields.empresaId.valid"
                        :label="fields.empresaId"
                        :editable="false"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                    <v-col v-if="!isClient" cols="4" lg="4" sm="12" xs="12">
                      <kore-select
                        v-model="projeto.trabalho"
                        :valid.sync="fields.trabalho.valid"
                        :label="fields.trabalho"
                        :editable="canEdit && cliente.trabalho == 3"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="3" sm="12" xs="12">
                      <kore-select
                        v-model="projeto.id_projeto"
                        :valid.sync="fields.id_projeto.valid"
                        :label="fields.id_projeto"
                        :editable="canEdit"
                      ></kore-select>
                    </v-col>
                    <v-col lg="9" sm="12" xs="12">
                      <kore-select
                        v-model="projeto.titulo"
                        :valid.sync="fields.titulo.valid"
                        :label="fields.titulo"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row class="align-center">
                    <v-col lg="6" sm="12" xs="12">
                      <kore-select
                        v-model="projeto.status"
                        :label="fields.status"
                        :editable="canEditStatus"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                    <v-col lg="6" sm="12" xs="12">
                      <kore-select
                        v-model="projeto.responsavelTxt"
                        :valid.sync="fields.responsavelTxt.valid"
                        :label="fields.responsavelTxt"
                        :editable="canEdit"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                    <v-col :lg="isClient ? 6 : 4" sm="12" xs="12">
                      <kore-select
                        v-model="projeto.data_inicio"
                        :valid.sync="fields.data_inicio.valid"
                        :label="fields.data_inicio"
                        :editable="canEdit"
                      ></kore-select>
                    </v-col>
                    <v-col :lg="isClient ? 6 : 4" sm="12" xs="12">
                      <kore-select
                        v-model="projeto.data_fim"
                        :valid.sync="fields.data_fim.valid"
                        :label="fields.data_fim"
                        :editable="canEdit"
                      ></kore-select>
                    </v-col>
                    <v-col
                      v-if="isGalaposUser"
                      lg="4"
                      sm="12"
                      xs="12"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        depressed
                        color="secondary"
                        class="px-5 mb-sm-5 mb-lg-0 w-100"
                        @click="
                          onPlurianualOpen();
                          plurianualDialog = true;
                        "
                      >
                        Gerar outro ano base
                      </v-btn>

                      <form-modal
                        :cols="plurianualField"
                        :maxWidth="plurianualDialogWidth"
                        :opened.sync="plurianualDialog"
                        :opts="opts"
                        title="Gerar Outro Ano Base"
                        :value.sync="plurianualData"
                        @save="gerarProximoAnoBase($event)"
                      ></form-modal>
                    </v-col>
                  </v-row>
                  <!-- TODO descomentar quando precisar do CPF, E-mail e Telefone do responsável -->
                  <!-- <v-row>
                    <v-col class="v-label-input font-weight-medium pl-5">
                      Responsável pelo {{ name }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-sheet outlined rounded class="px-2 mt-2 py-2">
                        <v-row>
                          <v-col cols="12">
                            <kore-select
                              v-model="projeto.responsavelTxt"
                              :valid.sync="fields.responsavelTxt.valid"
                              :label="fields.responsavelTxt"
                              :editable="canEdit"
                              :opts="opts"
                            ></kore-select>
                          </v-col>
                          <v-col cols="4">
                            <kore-select
                              v-model="projeto.responsavelId"
                              :label="fields.responsavelId_cpf"
                              :editable="false"
                              :opts="opts"
                            ></kore-select>
                          </v-col>
                          <v-col cols="4">
                            <kore-select
                              v-model="projeto.responsavelId"
                              :label="fields.responsavelId_email"
                              :editable="false"
                              :opts="opts"
                            ></kore-select>
                          </v-col>
                          <v-col cols="4">
                            <kore-select
                              v-model="projeto.responsavelId"
                              :label="fields.responsavelId_telefone"
                              :editable="false"
                              :opts="opts"
                            ></kore-select>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row> -->
                </v-window-item>

                <!-- TIPIFICAÇÃO -->
                <v-window-item>
                  <v-row v-if="isLeiDeInformatica">
                    <v-col>
                      <kore-select
                        v-model="projeto.tipo_projetoId"
                        :label="fields.tipo_projetoId"
                        :valid.sync="fields.tipo_projetoId.valid"
                        :editable="canEdit"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDeInformatica">
                    <v-col>
                      <kore-select
                        v-model="projeto.troca_ppb"
                        :label="fields.troca_ppb"
                        :valid.sync="fields.troca_ppb.valid"
                        :editable="canEdit && !isClient"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDeInformatica">
                    <v-col>
                      <kore-select
                        v-model="alcance"
                        :label="fields.alcance"
                        :valid.sync="fields.alcance.valid"
                        :editable="canEdit"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <kore-select
                        v-model="inovacao"
                        :label="fields.inovacao"
                        :valid.sync="fields.inovacao.valid"
                        :editable="canEdit"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDeInformatica">
                    <v-col cols="4">
                      <kore-select
                        v-model="projeto.qtde_publicacoes"
                        :valid.sync="fields.qtde_publicacoes.valid"
                        :label="fields.qtde_publicacoes"
                        :editable="canEdit"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDeInformatica">
                    <v-col>
                      <kore-select
                        v-model="decreto"
                        :label="fields.decreto"
                        :valid.sync="fields.decreto.valid"
                        :editable="canEdit && !isClient"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                  </v-row>
                </v-window-item>

                <!-- DETALHAMENTO -->
                <v-window-item>
                  <v-row>
                    <v-col>
                      <kore-select
                        v-model="projeto.descricao"
                        :label="fields.descricao"
                        :valid.sync="fields.descricao.valid"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDoBem">
                    <v-col>
                      <kore-select
                        v-model="projeto.tipo_pesquisaId"
                        :label="fields.tipo_pesquisaId"
                        :valid.sync="fields.tipo_pesquisaId.valid"
                        :editable="canEdit"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDoBem">
                    <v-col>
                      <kore-select
                        v-model="projeto.area_projeto"
                        :label="fields.area_projeto"
                        :valid.sync="fields.area_projeto.valid"
                        :editable="canEdit"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDoBem">
                    <v-col>
                      <kore-select
                        v-model="projeto.keywords"
                        :label="fields.keywords"
                        :valid.sync="fields.keywords.valid"
                        :editable="canEdit"
                        @keypress="onKeypressKeywordsDebounce"
                        @paste="onPasteKeywordsDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDoBem">
                    <v-col>
                      <kore-select
                        v-model="projeto.naturezaId"
                        :label="fields.naturezaId"
                        :valid.sync="fields.naturezaId.valid"
                        :editable="canEdit"
                        :opts="opts"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <kore-select
                        v-model="projeto.elemento_tecnologico"
                        :label="fields.elemento_tecnologico"
                        :valid.sync="fields.elemento_tecnologico.valid"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <kore-select
                        v-model="projeto.desafio_tecnologico"
                        :label="fields.desafio_tecnologico"
                        :valid.sync="fields.desafio_tecnologico.valid"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <kore-select
                        v-model="projeto.metodologia"
                        :label="fields.metodologia"
                        :valid.sync="fields.metodologia.valid"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDoBem">
                    <v-col cols="12">
                      <kore-select
                        v-model="projeto.atividade_continua"
                        :label="fields.atividade_continua"
                        :valid.sync="fields.atividade_continua.valid"
                        :editable="canEdit"
                      ></kore-select>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="projeto.atividade_continua === 'Sim'"
                    >
                      <kore-select
                        v-model="projeto.atividadeAnoBase"
                        :label="fields.atividadeAnoBase"
                        :valid.sync="fields.atividadeAnoBase.valid"
                        :editable="canEdit"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLeiDoBem">
                    <v-col>
                      <kore-select
                        v-model="projeto.info_complementar"
                        :label="fields.info_complementar"
                        :valid.sync="fields.info_complementar.valid"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                </v-window-item>

                <!-- RESULTADO -->
                <v-window-item>
                  <v-row>
                    <v-col>
                      <kore-select
                        v-model="projeto.resultado_economico"
                        :label="fields.resultado_economico"
                        :valid.sync="fields.resultado_economico.valid"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <kore-select
                        v-model="projeto.resultado_inovacao"
                        :label="fields.resultado_inovacao"
                        :valid.sync="fields.resultado_inovacao.valid"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <kore-select
                        v-model="projeto.recursos_humanos"
                        :label="fields.recursos_humanos"
                        :valid.sync="fields.recursos_humanos.valid"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <kore-select
                        v-model="projeto.vinculacao_materiais"
                        :label="fields.vinculacao_materiais"
                        :valid.sync="fields.vinculacao_materiais.valid"
                        :editable="canEdit"
                        @keypress="onKeypressDebounce"
                        @paste="onPasteDebounce"
                      ></kore-select>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-window-item>

          <v-window-item>
            <master-detail
              :canDelete="false"
              :canEdit="false"
              :cols="cols"
              :contextOptions="contextOptions"
              :customResource="projetoSelecaoCustomResource"
              :hasExportCSV="false"
              :hasFilter="false"
              :hasNewButton="canGroup"
              :opts="opts"
              @onOpenFormDialog="getProjetosDesagrupados"
            ></master-detail>
          </v-window-item>
        </v-window>
      </v-card>

      <GModal ref="confirmModal" appendTitleIcon="mdi-alert" title="Atenção!">
        <p>Você está deixando o descritivo com alterações não salvas.</p>
        <p>O que gostaria de fazer?</p>
        <template v-slot:buttons="{ close }">
          <v-btn
            class="px-3"
            color="primary"
            dark
            depressed
            @click="close(true)"
          >
            Salvar e sair
          </v-btn>
          <v-btn
            class="px-3"
            color="primary"
            dark
            depressed
            @click="close(false)"
          >
            Sair sem salvar
          </v-btn>
          <v-btn
            class="px-3"
            color="secondary"
            depressed
            dark
            @click="close(null)"
          >
            Cancelar
          </v-btn>
        </template>
      </GModal>
    </v-col>

    <v-col cols="3">
      <v-row>
        <v-col v-if="isAgrupamento" cols="12">
          <v-card class="teros-elevation">
            <v-card-actions class="px-2 py-4 justify-center">
              <v-btn
                v-if="currentView === 0"
                class="pa-2"
                color="primary"
                style="height: fit-content;"
                dark
                depressed
                @click="currentView = 1"
              >
                <v-icon left class="d-none d-lg-flex">mdi-table</v-icon>
                <span class="d-none d-lg-flex">Ver projetos agrupados</span>
                <span class="d-none d-md-flex d-lg-none">Ver projetos<br> agrupados</span>
                <span class="ma-2 d-sm-flex d-md-none">Ver <br> projetos<br> agrupados</span>
              </v-btn>
              <v-btn
                v-if="currentView === 1"
                class="pr-5"
                color="primary"
                dark
                depressed
                @click="currentView = 0"
              >
                <v-icon right>mdi-note-text</v-icon>
                Ver descritivo
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="teros-elevation">
            <v-card-subtitle class="pb-1">
              <uploader-v
                v-if="isClient"
                v-model="anexos"
                v-slot="{ selectFiles }"
              >
                <v-btn
                  class="mx-auto d-block px-2"
                  @click="selectFiles()"
                  dense
                  text
                >
                  <v-icon left>mdi-paperclip</v-icon>
                  Anexar arquivos
                </v-btn>
              </uploader-v>
              <span v-else class="v-label-input">Arquivos anexados:</span>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="py-0 pb-2">
              <template v-if="!anexos || anexos.length == 0">
                <v-alert
                  border="left"
                  color="primary"
                  outlined
                  dense
                  text
                  type="info"
                  class="v-label-input"
                >
                  Não existem anexos.
                </v-alert>
              </template>
              <v-card
                elevation="0"
                class="mb-2"
                v-for="(anexo, akey) in anexos"
                :key="akey"
              >
                <v-progress-linear
                  :color="anexo.error ? 'error' : 'secondary'"
                  :value="anexo.percent"
                  :striped="anexo.uploading"
                  height="2em"
                >
                  <v-card-text class="p-2">
                    <v-row
                      justify="space-between"
                      align="center"
                      no-gutters
                      class="py-2 pl-2 pr-0"
                    >
                      <span
                        class="v-label-input text-truncate"
                        :style="{ width: anexo.uploading ? '82%' : '85%' }"
                      >
                        <v-icon left>
                          {{
                            anexo.error
                              ? "mdi-file-document-alert"
                              : "mdi-file-document"
                          }}
                        </v-icon>
                        <span
                          :title="`${anexo.nome}${
                            !anexo.error ? '' : `\n${anexo.error}`
                          }`"
                        >
                          {{ anexo.error || anexo.nome }}
                        </span>
                      </span>
                      <span v-if="anexo.uploading" class="v-label-input">
                        {{ anexo.percent }}%
                      </span>
                      <v-btn
                        v-if="isClient"
                        small
                        icon
                        @click="anexos.splice(akey, 1)"
                      >
                        <v-icon small>mdi-close-circle-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        small
                        icon
                        :href="anexo.url"
                        target="_blank"
                      >
                        <v-icon small>mdi-download</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-progress-linear>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-sheet
            class="teros-elevation"
            rounded
            v-if="Object.keys(comentarios).length || !isClient"
          >
            <div class="v-label-input p-3">Comentários:</div>
            <v-select
              v-if="!isClient"
              dense
              hide-details
              outlined
              v-model="selectedComment"
              :items="commentsToAdd"
              item-value="key"
              item-text="value"
              @input="addFieldToComment($event)"
            ></v-select>
            <v-expansion-panels accordion>
              <v-expansion-panel
                v-for="(item, field) in comentarios"
                :key="field"
              >
                <v-expansion-panel-header
                  disable-icon-rotate
                  class="v-label-input px-4"
                >
                  {{ shortName(fields[field].name) }}
                  <template v-slot:actions>
                    <v-icon color="success">mdi-comment-outline</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="ex-panel-no-padding">
                  <v-textarea
                    v-model="comentarios[field]"
                    filled
                    dense
                    hide-details
                    :readonly="isClient"
                    :append-icon="isClient ? null : 'mdi-delete-outline'"
                    @click:append="removeFieldFromComment(field)"
                  ></v-textarea>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <v-sheet class="teros-elevation" rounded>
            <v-col cols="12" class="p-2">
              <v-col class="flex-lg-nowrap flex-shrink-1 flex-grow-0">
                Salvo pela ultima vez: {{ lastSaved }}
              </v-col>
            </v-col>
            <v-row class="m-0 justify-center">
              <v-col class="p-0 align-self-center text-center">
                <v-icon
                  color="primary"
                  dark
                  depressed
                  :disabled="!currentStep"
                  icon
                  @click="currentStep--"
                  right
                >
                  mdi-chevron-left
                </v-icon>
              </v-col>
              <v-col cols="7">
                <v-btn
                  color="primary"
                  dark
                  depressed
                  :disabled="!hasChanges"
                  width="100%"
                  @click="onBeforeDoSave"
                >
                  Salvar
                </v-btn>
              </v-col>
              <v-col class="p-0 align-self-center text-center">
                <v-icon
                  color="primary"
                  dark
                  depressed
                  :disabled="currentStep === 3"
                  icon
                  @click="currentStep++"
                  left
                >
                  mdi-chevron-right
                </v-icon>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
    <GModal
      ref="confirmStatus"
      appendTitleIcon="mdi-help-box-outline"
      title="Confirmação de status"
    >
      <p>Você está salvando o descritivo do projeto com o status:</p>
      <p>
        <b>{{ this.projeto.status_label }}</b>
      </p>
      <template v-slot:buttons="{ close }">
        <v-btn class="px-3" color="primary" dark depressed @click="close(true)">
          Confirmar
        </v-btn>
        <v-btn
          class="px-3"
          color="secondary"
          depressed
          dark
          @click="close(null)"
        >
          Cancelar
        </v-btn>
      </template>
    </GModal>
  </v-row>
</template>

<script>
import { BeneficiosEnum, BeneficiosLabels } from "@/core/enums/beneficios";
import { FieldTypeEnum } from "@/core/enums/field-types";
import { StatusProjetoEnum } from "@/core/enums/projetos";
import { AnyAdmin, UserTypeEnum } from "@/core/enums/user-types";
import getDifferences from "@/helpers/getDifferences";
import { projectsOptions } from "@/helpers/yearsOptions";
import _, { cloneDeep, isEmpty } from "lodash";
import moment from "moment";
import Vue from "vue";
import { mapGetters } from "vuex";
import { projetosEnquadradosFields } from "./projetos.enquadrados.fields";

export default {
  components: {
    "kore-select": () => import("@/components/input-v.vue"),
    "form-modal": () => import("@/components/form-modal.vue"),
    GModal: () => import("@/components/g-modal.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
    "uploader-v": () => import("@/components/uploader-v.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "jwtData", "user"]),
    canEdit: function () {
      const { status } = this.projeto;
      const { tipo_usuario } = this.user;
      // Se 'Em Revisão Galapos' ou 'Em validação final Galapos' somente Galapos pode editar
      // Aprovado para Submissão ou Submetido - ninguém pode editar, mas consultor ainda pode alterar o status
      return (
        ![
          StatusProjetoEnum.APROVADO_SUBMISSAO,
          StatusProjetoEnum.SUBMETIDO,
        ].includes(status) &&
        (![
          StatusProjetoEnum.REVISAO_GALAPOS,
          StatusProjetoEnum.VALIDACAO_FINAL_GALAPOS,
        ].includes(status) ||
          AnyAdmin.includes(tipo_usuario))
      );
    },
    canEditStatus: function () {
      const { status } = this.projeto;
      const { tipo_usuario } = this.user;

      // Aprovado para Submissão ou Submetido - ninguém pode editar, mas consultor ainda pode alterar o status
      return (
        this.canEdit ||
        ([
          StatusProjetoEnum.APROVADO_SUBMISSAO,
          StatusProjetoEnum.SUBMETIDO,
        ].includes(status) &&
          AnyAdmin.includes(tipo_usuario))
      );
    },
    canGroup: function () {
      return this.userHasAccess("Projetos.agrupamentos.create");
    },
    contextOptions: function () {
      return [
        {
          name: "Ver ficha de análise",
          limit: -1,
          icon: "",
          show: this.userHasAccess("Projetos.analise.create"),
          cb: ({ id }) => {
            const name = [
              UserTypeEnum.GERENCIAL_CLIENTE,
              UserTypeEnum.COLABORADOR,
            ].includes(this.user.tipo_usuario)
              ? "edicao-inclusao-projeto"
              : "ficha-projeto-analise";
            const { href } = this.$router.resolve({
              name,
              params: { projetoId: id },
            });
            window.open(href, "_blank");
          },
        },
        {
          name: "Remover",
          limit: -1,
          icon: "",
          show: this.userHasAccess("Projetos.agrupamentos.create"),
          cb: ({ id, titulo }) => {
            this.showConfirm(
              "Confirmar remoção",
              `Deseja remover ${titulo} do agrupamento?`,
              () => this.projetoSelecaoCustomResource.delete(id)
            );
          },
        },
      ].filter(({ show }) => !!show);
    },
    cols: function () {
      return [
        {
          key: "id_projeto",
          name: "Código",
          hideInform: true,
        },
        {
          key: "titulo",
          name: `Nome do ${this.name}`,
          hideInform: true,
        },
        {
          key: "trabalho",
          name: "Benefício",
          rel: { to: "trabalho", key: "id", name: "nome" },
          hideInform: true,
        },
        {
          key: "ano_base",
          name: "Ano Base",
          hideInform: true,
        },
        {
          key: "data_inicio",
          name: "Data Inicial",
          type: FieldTypeEnum.DATE,
          hideInform: true,
        },
        {
          key: "data_fim",
          name: "Data Final",
          type: FieldTypeEnum.DATE,
          hideInform: true,
        },
        {
          key: "projetosSelecionados",
          name: "Projetos sem grupo",
          type: FieldTypeEnum.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "projetosDesagrupados", key: "id", name: "titulo" },
          hideInTable: true,
        },
      ];
    },
    plurianualField: function () {
      return [
        {
          key: "ano_base",
          name: "Gerar para o ano:",
          type: FieldTypeEnum.SELECT,
          rules: [{ rule: "required" }],
          rel: { toEdit: projectsOptions, id: "value", name: "text" },
          show: true,
          colSize: this.isAgrupamento ? 4 : 12,
        },
        {
          key: "projetos_agrupados",
          name: "Projetos desse agrupamento que serão replicadas junto do agrupamento",
          placeholder:
            "Nenhum projeto selecionado, agrupamento sera replicado vazio",
          type: FieldTypeEnum.AUTOCOMPLETE_MULTIPLE,
          rel: { to: this.projetosAgrupados, key: "id", name: "titulo" },
          show: this.isAgrupamento,
          colSize: 12,
        },
      ].filter(({ show }) => show);
    },
    plurianualDialogWidth: function () {
      return this.isAgrupamento ? "60%" : "20%";
    },
    fields: function () {
      const fields = _.cloneDeep(projetosEnquadradosFields);

      fields.forEach((field) => {
        field.name = field.name.replace(/Projeto/g, this.name);
      });

      return fields.reduce(
        (acc, field) => ({ ...acc, [field.key]: field }),
        {}
      );
    },
    hasChanges: function () {
      const diff = getDifferences(this.originalProject, this.projeto);
      return !isEmpty(diff);
    },
    isAgrupamento: function () {
      return this.projeto?.agrupamento;
    },
    isGalaposUser: function () {
      return [UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CONSULTOR].includes(
        this.user.tipo_usuario
      );
    },
    isLeiDoBem: function () {
      return [
        BeneficiosEnum.LEI_DO_BEM,
        BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
      ].includes(this.projeto?.trabalho);
    },
    isLeiDeInformatica: function () {
      return [
        BeneficiosEnum.LEI_DE_INFORMATICA,
        BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
      ].includes(this.projeto?.trabalho);
    },
    lastSaved: function () {
      const { modificado_em } = this.projeto;
      return modificado_em
        ? moment(modificado_em).locale("pt-br").format("LLL")
        : "nunca";
    },
    name: function () {
      return this.projeto?.projetos_agrupados?.length > 0
        ? "Agrupamento"
        : "Projeto";
    },
    projetoSelecaoCustomResource: function () {
      const resource = this.apiResource(
        `/v1/projetos/${this.clientId}/agrupamento`
      );
      const { projetos_agrupados } = this.projeto;
      const updateAgrupamento = async ({ add = [], exclude = [] }) => {
        const { ano_base, id, projetos_agrupados, titulo } = this.projeto;
        const projetos = projetos_agrupados
          .map(({ id }) => id)
          .concat(add)
          .filter((id) => !exclude.includes(id));
        const body = { ano_base, id, projetos, titulo };
        let response = await resource.save(body, id);

        if (response.error) {
          throw response;
        }

        response = await this.resourceProjetos.get(id);

        if (response.error) {
          throw response;
        }

        this.projeto.projetos_agrupados = response.projeto.projetos_agrupados;
        return response.projeto.projetos_agrupados;
      };

      return {
        async get() {
          return projetos_agrupados;
        },
        save({ projetosSelecionados }) {
          return updateAgrupamento({ add: projetosSelecionados });
        },
        delete(id) {
          return updateAgrupamento({ exclude: [id] });
        },
      };
    },
    resourceProjetos: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/projeto`);
    },
    resourceProjetosSelecao: function () {
      return this.apiResource(
        `/v1/projetos/${this.clientId}/selecao?ano=${this.projeto.ano_base}`
      );
    },
    resourceStatus: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/status`);
    },
    isClient: function () {
      return this.getClient().isClient;
    },
    commentsToAdd: function () {
      return _.differenceWith(
        Object.keys(this.fields).filter((k) => !this.fields[k].nao_comenta),
        Object.keys(this.comentarios),
        (k1, k2) => {
          return k1 == k2;
        }
      ).map((k) => ({ key: k, value: this.shortName(this.fields[k].name) }));
    },
    cliente: function () {
      return (
        _.find(this.opts.clientes, (c) => c.id == this.projeto.empresaId) || {
          trabalho: 0,
        }
      );
    },
  },
  beforeRouteLeave: async function (_to, _from, next) {
    if (!this.hasChanges) {
      next();
      return;
    }

    const shouldSave = await this.$refs.confirmModal.asyncOpen();

    if (shouldSave) {
      await this.doSave();
    }

    const shouldLeave = shouldSave != null;

    if (shouldLeave) {
      next();
    } else {
      next(false);
    }
  },
  beforeDestroy: function () {
    window.removeEventListener("beforeunload", this.confirmCloseWindow);
  },
  created: function () {
    window.addEventListener("beforeunload", this.confirmCloseWindow);
    this.doLoad();

    this.resourceStatus.get().then((response) => {
      this.opts.status = response
        .sort((a, b) => a.ordem - b.ordem)
        .reduce((acc, curr) => {
          if (!curr.label) {
            return acc;
          }

          if (
            [
              StatusProjetoEnum.PENDENTE_ANALISE,
              StatusProjetoEnum.ATRASO,
              StatusProjetoEnum.INFORMACOES_PENDENTES,
              StatusProjetoEnum.NAO_ENQUADRADO,
            ].includes(curr.id)
          ) {
            curr.disabled = true;
          }

          return [...acc, curr];
        }, []);
    });

    this.onKeypressDebounce = _.debounce(this.onKeypress, 5100, {
      leading: true,
      trailing: false,
    });
    this.onKeypressKeywordsDebounce = _.debounce(
      this.onKeypressKeywords,
      5100,
      { leading: true, trailing: false }
    );
    this.onPasteDebounce = _.debounce(this.onPaste, 5100, {
      leading: true,
      trailing: false,
    });
    this.onPasteKeywordsDebounce = _.debounce(this.onPasteKeywords, 500, {
      leading: true,
      maxWait: 1000,
      trailing: false,
    });
  },
  methods: {
    confirmCloseWindow: function (event) {
      if (this.hasChanges) {
        event.preventDefault();
        event.returnValue = true;
      }
    },
    doLoad: function () {
      return this.resourceProjetos
        .get(this.$route.params.projetoId)
        .then((response) => {
          this.projeto = response.projeto;
          this.projeto.keywords =
            response.projeto.keywords?.split(";").map((word) => word.trim()) ||
            [];

          this.inovacao = JSON.parse(
            !this.projeto.inovacao || this.projeto.inovacao == ""
              ? "[]"
              : this.projeto.inovacao
          );
          this.decreto = JSON.parse(
            !this.projeto.decreto || this.projeto.decreto == ""
              ? "[]"
              : this.projeto.decreto
          );
          this.alcance = JSON.parse(
            !this.projeto.alcance || this.projeto.alcance == ""
              ? "[]"
              : this.projeto.alcance
          );
          this.comentarios = JSON.parse(
            !this.projeto.comentario || this.projeto.comentario == ""
              ? "{}"
              : this.projeto.comentario
          );
          this.anexos = JSON.parse(
            !this.projeto.anexo || this.projeto.anexo == ""
              ? "[]"
              : this.projeto.anexo
          );

          /* ENQUANTO NÂO NO PROJETO  */
          if (typeof this.projeto.trabalho == "undefined") {
            this.projeto.trabalho = response.clientes.trabalho;
          }

          this.opts.clientes = [response.clientes];
          this.opts.tipoProjeto = response.tipoProjeto;
          this.opts.alcance = response.alcance;
          this.opts.inovacao = response.inovacao;
          this.opts.areaAplicacao = response.areaAplicacao;
          this.opts.decreto = response.decreto;
          this.opts.tipoPesquisa = response.tipoPesquisa;
          this.opts.areaProjeto = response.areaProjeto
            .map((s) => s.descricao)
            .sort();
          this.opts.natureza = response.natureza;
          this.projetosAgrupados = this.formatProjetosOptions(
            response.projeto.projetos_agrupados
          );

          this.originalProject = cloneDeep(this.projeto);
        });
    },
    formatProjetosOptions: function (projetos) {
      if (!Array.isArray(projetos)) {
        return [];
      }

      return projetos
        .map((projeto) => {
          const trabalho =
            BeneficiosLabels[projeto.trabalho] || "Sem Benefício";
          const titulo = `${
            projeto.titulo || "Título não encontrado"
          } [${trabalho}]`;
          const disabled = this.projeto.trabalho != projeto.trabalho;
          return { ...projeto, disabled, titulo };
        })
        .sort((a, b) => {
          const { titulo: tituloA, trabalho: trabalhoA } = a;
          const { titulo: tituloB, trabalho: trabalhoB } = b;
          const disabledA = a.disabled ? 1 : 0;
          const disabledB = b.disabled ? 1 : 0;
          return (
            disabledA - disabledB ||
            trabalhoA - trabalhoB ||
            tituloA.localeCompare(tituloB)
          );
        });
    },
    getProjetosDesagrupados: async function () {
      const { projetos } = await this.resourceProjetosSelecao.get();
      const projetosDesagrupados = projetos?.filter(
        ({ agrupamento_id }) => !agrupamento_id
      );
      this.opts.projetosDesagrupados =
        this.formatProjetosOptions(projetosDesagrupados);
    },
    removeFieldFromComment: function (key) {
      Vue.delete(this.comentarios, key);
      this.selectedComment = {};
    },
    addFieldToComment: function (key) {
      Vue.set(this.comentarios, key, "");
      this.selectedComment = {};
    },
    shortName: function (nameField) {
      let idxParentesis = nameField.indexOf(" (");
      return nameField.substring(
        0,
        idxParentesis > 0 ? idxParentesis : nameField.length
      );
    },
    doSave: function () {
      this.projeto.empresaId = this.clientId;
      this.projeto.comentario = JSON.stringify(this.comentarios);
      this.projeto.anexo = JSON.stringify(this.anexos);
      this.projeto.inovacao = this.inovacao;
      this.projeto.decreto = this.decreto;
      this.projeto.alcance = this.alcance;

      if (
        this.cliente.trabalho != 3 &&
        this.projeto.trabalho != this.cliente.trabalho
      ) {
        this.projeto.trabalho = this.cliente.trabalho;
      }

      const projeto = _.cloneDeep(this.projeto);
      projeto.keywords = !projeto.keywords.length
        ? ""
        : projeto.keywords.join("; ");
      return this.resourceProjetos
        .save(projeto, this.projeto.id)
        .then((response) => {
          this.projeto.id = response.id;
          this.doLoad();
          return response;
        });
    },
    gerarProximoAnoBase: function ({ ano_base, projetos_agrupados }) {
      const dataFimProjeto = this.projeto.data_fim;
      const dataAnoBase = moment(dataFimProjeto).get("year");
      if (dataFimProjeto && dataAnoBase < ano_base) {
        const dataFimExibicao = moment(dataFimProjeto).format("DD/MM/YYYY");
        this.showConfirm(
          `Projeto encerrado em ${dataFimExibicao}`,
          `Deseja prosseguir com a replicação do projeto para o ano ${ano_base}? O novo projeto será criado com data fim indefinida.`,
          () => {
            this.gerarAnoBase(ano_base, projetos_agrupados);
          }
        );
      } else {
        this.gerarAnoBase(ano_base, projetos_agrupados);
      }
    },
    gerarAnoBase: function (ano_base, projetos_agrupados) {
      this.apiResource(
        `v1/projetos/${this.clientId}/plurianual/${this.projeto.id}`
      )
        .save({ ano_base, projetos_agrupados })
        .then((response) => {
          if (response.error) {
            return;
          }

          this.plurianualDialog = false;
          const route = this.$router.resolve({
            name: "ficha-projeto-enquadrado",
            params: { projetoId: response.id },
          });
          window.open(route.href, "_blank");
        });
    },
    onPlurianualOpen: function () {
      this.plurianualData = {
        projetos_agrupados: this.isAgrupamento
          ? this.projeto.projetos_agrupados.map(({ id }) => id)
          : undefined,
      };
    },
    onKeypress: function (event) {
      const checkLength = () => {
        const { maxLength, value } = event.target;

        if (maxLength > -1 && value.length >= maxLength) {
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "alert",
            title: "Limite de caracteres atingido",
            text: `Você não pode inserir mais de ${maxLength} caracteres neste campo.`,
          });
        }
      };

      // Compensar o delay entre o evento keypress e o change do componente
      setTimeout(checkLength, 100);
    },
    onKeypressKeywords: function (event) {
      const { maxLength, length } = event.target;
      if (maxLength > -1 && length >= maxLength) {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "alert",
          title: "Limite de caracteres atingido",
          text: `Você não pode inserir mais de ${maxLength} caracteres neste campo.`,
        });
      }
    },
    onPaste: function (event) {
      const currText = event.target.value;
      const maxLength = event.target.maxLength;
      const pasteText = event.clipboardData.getData("text");
      const nextLength = currText.length + pasteText.length;

      if (maxLength > -1 && nextLength > maxLength) {
        event.preventDefault();
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "alert",
          title: "Ação colar interrompida",
          text: `A ação iria exceder o limite de ${maxLength} caracteres do campo (iria para ${nextLength} caracteres).`,
        });
      }
    },
    onPasteKeywords: function ({ target, pasteData }) {
      const { maxLength, value } = target;
      const parsedValue = value.join("; ");
      const nextLength = parsedValue.length + pasteData.length;

      if (
        maxLength > -1 &&
        nextLength > maxLength &&
        parsedValue !== pasteData
      ) {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "alert",
          title: "Ação colar interrompida",
          text: `A ação iria exceder o limite de ${maxLength} caracteres do campo (iria para ${nextLength} caracteres).`,
        });
      }
    },
    onBeforeDoSave: async function () {
      const confirmModal = await this.$refs.confirmStatus.asyncOpen();
      if (confirmModal) {
        this.doSave();
      }
    },
  },
  data: function () {
    return {
      onKeypressDebounce: () => {},
      onKeypressKeywordsDebounce: () => {},
      onPasteDebounce: () => {},
      onPasteKeywordsDebounce: () => {},
      projetosAgrupados: [],
      plurianualDialog: false,
      plurianualData: {},
      status: [],
      selectedComment: null,
      currentStep: 0,
      currentView: 0,
      anexos: [],
      inovacao: [],
      decreto: [],
      alcance: [],
      comentarios: {},
      originalProject: {},
      projeto: {
        id: null,
        trabalho: null,
        id_projeto: null,
        criado_em: null,
        modificado_em: null,
        empresaId: null,
        consultorId: null,
        // responsavelId: null,
        responsavelTxt: null,
        titulo: null,
        resumo: null,
        anexo: null,
        descricao: null,
        enquadramento: null,
        status: null,
        data_analise: null,
        data_inicio: null,
        data_fim: null,
        ano_base: null,
        tipo_projetoId: null,
        troca_ppb: null,
        area_aplicacaoId: null,
        qtde_publicacoes: null,
        tipo_pesquisaId: null,
        area_projeto: null,
        keywords: [],
        naturezaId: null,
        elemento_tecnologico: null,
        desafio_tecnologico: null,
        metodologia: null,
        atividade_continua: null,
        info_complementar: null,
        resultado_economico: null,
        resultado_inovacao: null,
        projetos_agrupados: [],
        recursos_humanos: null,
        vinculacao_materiais: null,
      },
      opts: {
        alcance: [],
        areaAplicacao: [],
        areaProjeto: [],
        clientes: [],
        colaboradores: [],
        inovacao: [],
        decreto: [],
        tipoProjeto: [],
        status: [],
        tipoPesquisa: [],
        natureza: [],
        projetosDesagrupados: [],
        trabalho: [
          {
            id: 0,
            nome: "Nenhum",
            disabled: true, // essa opção pode vir assim, mas não deve ser selecionavel
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM,
            nome: BeneficiosLabels.LEI_DO_BEM,
          },
          {
            id: BeneficiosEnum.LEI_DE_INFORMATICA,
            nome: BeneficiosLabels.LEI_DE_INFORMATICA,
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
            nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-progress-linear__content {
  .v-btn > .v-btn__content .v-icon,
  .v-label-input {
    &,
    & .theme--light.v-icon {
      color: rgba(255, 255, 255, 0.87);
    }
  }
}

::v-deep .theme--dark.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.12) !important;
  background-color: rgba(255, 255, 255, 0.12) !important;
}

::v-deep .v-btn:disabled,
.theme--dark.v-btn:disabled,
.theme--dark.v-btn:disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.28) !important;
}

.ex-panel-no-padding .v-expansion-panel-content__wrap {
  padding: 0px;
}

.ex-panel-no-padding .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
}
</style>
